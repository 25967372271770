class Emittor {
  constructor() {
    this._events = {};
  }

  on(name, listener, ctx) {
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push({ cb: listener, ctx: ctx });
  }

  emit(name, data) {
    if (!this._events[name]) {
      throw new Error(`Can't emit an event. Event "${name}" doesn't exits.`);
    }

    const fireCallbacks = (listener) => {
      listener.cb.apply(listener.ctx, [data]);
    };

    this._events[name].forEach(fireCallbacks);
  }
}
window.Eventz = new Emittor()
  