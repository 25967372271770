const RANGES = ['today', 'yesterday', '7-days', '30-days', 'current-month', 'last-month', 'this-year', 'last-year', 'all-time']

class UrlBoss {
  constructor() {
  }
  
  getApp() {
    if(window.location.pathname.startsWith('/game/') || window.location.pathname.startsWith('/app/')) {
      const pathParts = window.location.pathname.split('/')
      return pathParts.length > 2 ? pathParts[2] : ''
    }
    return ''
  }
  
  setApp(appId) {
    if(window.location.pathname.startsWith('/game/') || window.location.pathname.startsWith('/app/')) {
      const pathParts = window.location.pathname.split('/')
      if(pathParts.length > 2) pathParts[2] = appId

      let searchParams = this._toString()

      window.history.pushState({}, '', `${pathParts.join('/')}${searchParams}`)
    }
  }

  getBaseDate() {
    return new URLSearchParams(window.location.search).get('baseDate')
  }

  getRange() {
    const searchParams = new URLSearchParams(window.location.search)
    if(!searchParams.has('range')) return '7-days'
    const range = searchParams.get('range')
    if(RANGES.includes(range)) return range
    else return '7-days'
  }

  getRangeString() {
    const range = this.getRange()
    if(range) return `&range=${range}`
    else return ''
  }
  
  setRange(range) {
    const searchParams = new URLSearchParams(window.location.search)
    if(searchParams.get('range') === range) return
    searchParams.set('range', range)
    window.history.pushState({}, '', `?${searchParams.toString()}`)
  }
  
  getCategory() {
    const searchParams = new URLSearchParams(window.location.search)
    if(!searchParams.has('category')) return 'engagement'
    return searchParams.get('category')
  }
  
  setCategory(cat) {
    const searchParams = new URLSearchParams(window.location.search)
    if(searchParams.get('category') === cat) return
    searchParams.set('category', cat)
    window.history.pushState({}, '', `?${searchParams.toString()}`)
  }
  
  getFilterString() {
    const searchParams = new URLSearchParams(window.location.search)
    const parts = []
    
    const filters = ['build', 'countryCode', 'platform', 'osVersion', 'device', 'deviceWidth', 'timezone', 'lang']
    filters.forEach(f => {
      if(searchParams.has(f)) {
        parts.push(`${f}=${searchParams.get(f)}`)
      }
    })
    
    if(parts.length == 0) return ''
    
    return `&${parts.join('&')}`
  }

  getChartOptionsString() { // used for appending filter and range to category links
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('category')
    return searchParams.toString()
  }
  
  setFilters(filters) {
    if(!filters || filters.length == 0) return
    
    const searchParams = new URLSearchParams(window.location.search)
    
    filters.forEach(f =>  {
      if(f.appliedValues && f.appliedValues.length > 0) searchParams.set(f.name, f.appliedValues.join(':-;'))
      else searchParams.delete(f.name)
    })
    
    // avoid pushing the same searchparams
    const previousSearchParams = new URLSearchParams(window.location.search)
    if(previousSearchParams.toString() === searchParams.toString()) return
    
    window.history.pushState({}, '', `?${searchParams.toString()}`)
  }
  
  _toString() {
    const searchParams = new URLSearchParams(window.location.search).toString()
    return searchParams.length > 0 ? `?${searchParams}`: ''
  }

}
window.UrlBoss = new UrlBoss()
  
