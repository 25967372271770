class Redirector {
  
  constructor() {
    this.redirecting = false
    
    window.Eventz.on('REDIRECT_TO_LOGIN', function() {
      if(this.redirecting) return
      this.redirecting = true
      window.location = '/login'
    }, this)
  }

}
window.Redirector = new Redirector()
  